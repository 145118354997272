<template>
  <div v-if="page" class="container">
    <h1>{{ page.title.rendered }}</h1>
    <div v-html="page.content.rendered" />
  </div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      page: null
    }
  },
  methods: {
    async fetchPage () {
      if (this.$route.query.preview) {
        this.page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        this.page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchPage())
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/container";

.container {
  @extend %container;
}
</style>
